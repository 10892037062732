import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const CouponBookReplacementSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="Replacement Coupon Book Submitted"
    content="Thank you. Your coupon replacement request has been received. You will be receiving your coupons by U.S. Mail within approximately five business days."
  />
);

export default CouponBookReplacementSuccess;
